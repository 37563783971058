import './polyfills';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import keycloak from './auth/keycloak'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { locale, loadMessages } from "devextreme/localization";
import deMessages from "./localization/dx-pl.json";

const eventLogger = (event: unknown, error: unknown) => {
  console.debug('onKeycloakEvent', event, error)
}

const tokenLogger = (tokens: unknown) => {
  console.debug('onKeycloakTokens', tokens)
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

loadMessages(deMessages);
locale(navigator.language);

root.render(
  <ReactKeycloakProvider authClient={keycloak} initOptions={{ onLoad: 'login-required' }} onEvent={eventLogger}
    onTokens={tokenLogger}>
    <App />
  </ReactKeycloakProvider>
);
