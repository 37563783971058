import { Routes, Route, Navigate } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import { Footer } from './components';
import { LocationProvider } from './contexts/localization';

export default function Content() {
  return (
    <LocationProvider>
      <SideNavBarLayout title={appInfo.title}>
        <Routes>
          {routes.map(({ path, element }) => (
            <Route
              key={path}
              path={path}
              element={element}
            />
          ))}
          <Route
            path='*'
            element={<Navigate to='/energy' />}
          />
        </Routes>
        {/* <Footer>
      </Footer> */}
      </SideNavBarLayout>
    </LocationProvider>
  );
}

