import React, { useState } from 'react';
import { SelectBox, DateBox, Button } from 'devextreme-react';
import FileSaver from 'file-saver';
import { BASE_URL } from '../../api/Api';


const aggregationOptions = [
  { value: '15m', label: 'piętnaście minut' },
  { value: '30m', label: 'trzydzieści minut' },
  { value: '1h', label: 'jedna godzina' },
  { value: '3h', label: 'trzy godziny' },
  { value: '6h', label: 'sześć godzin' },
  { value: '12h', label: 'dwanaście godzin' },
  { value: '24h', label: 'dwadzieścia cztery godziny' },
];

const formatDate = (date: Date) => {
  return date.toLocaleDateString('en-GB').split('/').reverse().join('-');
};

export const ReportsPage: React.FC = () => {
  const [aggregationType, setAggregationType] = useState<string>('15m');
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const handleGenerateClick = async () => {
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    const fileName = `${aggregationType}_${formattedStartDate}_${formattedEndDate}.csv`;

    try {
      const response = await fetch(BASE_URL +`/api/EnergyUsage/csv?aggregationType=${aggregationType}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`, {
        method: 'GET',
      });
      if (response.ok) {
        const blob = await response.blob();
        FileSaver.saveAs(blob, fileName);
      } else {
        console.error('Failed to fetch the CSV file.');
      }
    } catch (error) {
      console.error('Network error while fetching the CSV file:', error);
    }
  };

  return (
    <div>
      <div>
        <strong>Typ agregacji:</strong>
        <SelectBox
          dataSource={aggregationOptions}
          displayExpr="label"
          valueExpr="value"
          value={aggregationType}
          onValueChanged={(e) => setAggregationType(e.value)}
        />
      </div>
      <div>
        <strong>Data początkowa:</strong>
        <DateBox
          type="date"
          displayFormat="dd-MM-yyyy"
          onValueChanged={(e) => setStartDate(e.value)}
          value={startDate}
        />
      </div>
      <div>
        <strong>Data końcowa:</strong>
        <DateBox
          type="date"
          displayFormat="dd-MM-yyyy"
          onValueChanged={(e) => setEndDate(e.value)}
          value={endDate}
        />
      </div>
      <Button
        text="Generuj"
        type="success"
        onClick={handleGenerateClick}
      />
    </div>
  );
};
