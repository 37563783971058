import React from 'react';

const InstallationInfoTable = ({ data }) => {
    return (
        <div >
            <h2>Informacje o instalacji</h2>
            <table>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            <th>{item.title}</th>
                            <td>{item.value}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};


export default InstallationInfoTable;
