import { DataResponse, SeriesConfig } from "./EnergyConsumptionChart";
import { IDataSource, PVDataRange } from './IDataSource';
import { GetCurrentMyWayyApi } from "../../api/Api";

const formatDate = (date: Date): string => {

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const yyyy = year.toString();
  const mm = (month < 10 ? '0' : '') + month.toString();
  const dd = (day < 10 ? '0' : '') + day.toString();
  return `${dd}.${mm}.${yyyy}`;
};

const formatDateForDay = (date: Date): string => {

  const hour = date.getHours();
  return `${hour}`;
};

const formatDateForDayAsPower = (date: Date): string => {

  const hour = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  return `${hour}:${minutes}`;
};

const formatForWeek = (date: Date): string => {
  const daysOfWeek = ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'];
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  return `${daysOfWeek[date.getDay()]}\n${day}.${month}`;
};


const formatForMonth = (date: Date): string => {
  const day = date.getDate();
  return `${day}`;
};

const formatForYear = (date: Date): string => {
  const monthsOfYear = ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'];
  return monthsOfYear[date.getMonth()];
};



export class MyWayyDataSource implements IDataSource {

  async getData(pvId: string, range: PVDataRange, day: number, month: number, year: number): Promise<DataResponse> {

    const resp = await GetCurrentMyWayyApi().PVApi.apiPVApiPVInstallationIdGet(pvId, range, year, month, day);

    if (!resp.data.series) return {} as DataResponse;

    let formatArgument: (arg: Date) => string;
    switch (range) {
      case PVDataRange.Day:
        formatArgument = formatDateForDayAsPower;
        break;
      case PVDataRange.Week:
        formatArgument = formatForWeek;
        break;
      case PVDataRange.Month:
        formatArgument = formatForMonth;
        break;
      case PVDataRange.Year:
        formatArgument = formatForYear;
        break;
      default:
        formatArgument = formatDate;
    }

    const ret = (resp.data.series).map(x => {
      return {
        argument: x.date ? formatArgument(new Date(x.date)) : "?",
        pvProduction: (x.values ? x.values[0] : null),
      }
    })
    return {
      total: resp.data.stats?.total ?? 0,
      series: ret,
      unit: range === PVDataRange.Day ? "kW" : "kWh",
    } as DataResponse;

  }



  getSeriesConfigs(): SeriesConfig[] {
    const seriesConfigs = [

      // { name: 'Zużycie Energii', valueField: 'energyConsumption', color: '#7E6897' },
      { name: 'Produkcja PV', valueField: 'pvProduction', color: '#34A853' },
    ];

    return seriesConfigs;
  }
}