export const navigation = [
  // {
  //   text: 'Home',
  //   path: '/home',
  //   icon: 'home'
  // },
  // {
  //   text: 'Urządzenia',
  //   path: '/devices',
  //   icon: 'activefolder'
  // },
  {
    text: 'Energia',
    path: '/energy',
    icon: 'chart'
  },
  {
    text: 'Raporty',
    path: '/reports',
    icon: 'file'
  }
];
