import React, { createContext, useContext, useState, ReactNode } from 'react';
import { ApiObject, GetMyWayyApi, SetBaseUrl } from '../api/Api';

type LocationContextType = {
    location: string | undefined;
    locations: { value: string, label: string }[];
    handleLocationChange: (newLocation: string) => void;
    // serverApi: ApiObject;
};

const LocationContext = createContext<LocationContextType | undefined>(undefined);

export const useLocation = () => {
    const context = useContext(LocationContext);
    if (!context) throw new Error('useLocation must be used within a LocationProvider');
    return context;
};

type LocationProviderProps = {
    children: ReactNode;
};

const defaultLocation = 'abramczyk-kapitanska';

const locations = (process.env.NODE_ENV === "production") ?
    [
        { value: 'abramczyk-kapitanska', label: 'ABRAMCZYK Sp. z o.o. Kapitańska', url: 'https://app.wayy.pl/data/mw-abramczyk-kapitanska' },
        { value: 'abramczyk-inflancka', label: 'ABRAMCZYK Sp. z o.o. Inflancka', url: 'https://app.wayy.pl/data/mw-abramczyk-inflancka' },
    ] :
    [
        { value: 'abramczyk-kapitanska', label: 'ABRAMCZYK Sp. z o.o. Kapitańska', url: 'http://localhost:9000' },
        { value: 'abramczyk-inflancka', label: 'ABRAMCZYK Sp. z o.o. Inflancka', url: 'http://localhost:9000' },
        //  { value: 'abramczyk-kapitanska', label: 'ABRAMCZYK Sp. z o.o. Kapitańska', url: 'https://app.wayy.pl/data/mw-abramczyk-kapitanska' },
        //  { value: 'abramczyk-inflancka', label: 'ABRAMCZYK Sp. z o.o. Inflancka', url: 'https://app.wayy.pl/data/mw-abramczyk-inflancka' },
    ];

SetBaseUrl(locations.find(l => l.value === defaultLocation)?.url ?? "")

export const LocationProvider: React.FC<LocationProviderProps> = ({ children }) => {
    const [location, setLocation] = useState<string | undefined>(defaultLocation);
    //const [serverApi, setServerApi] = useState<ApiObject>(GetMyWayyApi("http://localhost:9000"));

    const handleLocationChange = (newLocation: string) => {
        setLocation(newLocation);
        SetBaseUrl(locations.find(l => l.value === newLocation)?.url ?? "");
    };



    return (
        <LocationContext.Provider value={{ location, locations, handleLocationChange }}>
            {children}
        </LocationContext.Provider>
    );
};
