import axios from "axios"
import { TestApi, CentralApi, PVApi, EnergyUsageApi } from "./openapi"

export let BASE_URL = "."

export const SetBaseUrl = (url: string) => { BASE_URL = url }

export interface ApiObject {
    BASE_URL: string;
    CentralApi: CentralApi;
    TestApi: TestApi;
    PVApi: PVApi;
    EnergyUsageApi: EnergyUsageApi;
}

export const GetMyWayyApi = (() => {
    const cache: { [key: string]: ApiObject } = {};;

    return (baseUrl: string) => {
        if (cache[baseUrl]) {
            return cache[baseUrl];
        }

        const apiObject = {
            BASE_URL: baseUrl,
            CentralApi: new CentralApi(undefined, baseUrl),
            TestApi: new TestApi(undefined, baseUrl),
            PVApi: new PVApi(undefined, baseUrl),
            EnergyUsageApi: new EnergyUsageApi(undefined, baseUrl),
        };

        cache[baseUrl] = apiObject;

        return apiObject;
    };
})();


export const GetCurrentMyWayyApi = () => GetMyWayyApi(BASE_URL)

let GetToken = () => ""

export const SetGetToken = (getToken: () => string) => { GetToken = getToken }

axios.interceptors.request.use(async (config) => {
    const token = await GetToken()
    config.headers["Authorization"] = `Bearer ${token}`
    return config;
});