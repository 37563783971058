/* tslint:disable */
/* eslint-disable */
/**
 * MyWayy.Server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const AccountStatus = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type AccountStatus = typeof AccountStatus[keyof typeof AccountStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const AccountType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type AccountType = typeof AccountType[keyof typeof AccountType];


/**
 * 
 * @export
 * @enum {string}
 */

export const AggregationType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type AggregationType = typeof AggregationType[keyof typeof AggregationType];


/**
 * 
 * @export
 * @interface Central
 */
export interface Central {
    /**
     * 
     * @type {string}
     * @memberof Central
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Central
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Central
     */
    'area'?: string | null;
    /**
     * 
     * @type {Array<CentralParameter>}
     * @memberof Central
     */
    'parameters'?: Array<CentralParameter> | null;
    /**
     * 
     * @type {Array<CentralAlert>}
     * @memberof Central
     */
    'alarms'?: Array<CentralAlert> | null;
    /**
     * 
     * @type {Array<CentralChart>}
     * @memberof Central
     */
    'charts'?: Array<CentralChart> | null;
    /**
     * 
     * @type {string}
     * @memberof Central
     */
    'lastModified'?: string;
    /**
     * 
     * @type {PlcStatus}
     * @memberof Central
     */
    'statusCentral'?: PlcStatus;
    /**
     * 
     * @type {number}
     * @memberof Central
     */
    'maxParametersForCentral'?: number;
    /**
     * 
     * @type {number}
     * @memberof Central
     */
    'parametersCountSaveDb'?: number;
    /**
     * 
     * @type {string}
     * @memberof Central
     */
    'lastReadDateTime'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Central
     */
    'tags'?: Array<string> | null;
    /**
     * 
     * @type {RoutesGroup}
     * @memberof Central
     */
    'messageRoutes'?: RoutesGroup;
    /**
     * 
     * @type {string}
     * @memberof Central
     */
    'ip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Central
     */
    'kprojPrefix'?: string | null;
}


/**
 * 
 * @export
 * @interface CentralAlert
 */
export interface CentralAlert {
    /**
     * 
     * @type {string}
     * @memberof CentralAlert
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentralAlert
     */
    'plcId'?: string | null;
    /**
     * 
     * @type {PlcMessageType}
     * @memberof CentralAlert
     */
    'type'?: PlcMessageType;
    /**
     * 
     * @type {string}
     * @memberof CentralAlert
     */
    'riseParameter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentralAlert
     */
    'resetParameter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentralAlert
     */
    'longText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentralAlert
     */
    'shortText'?: string | null;
}


/**
 * 
 * @export
 * @interface CentralChart
 */
export interface CentralChart {
    /**
     * 
     * @type {number}
     * @memberof CentralChart
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CentralChart
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CentralChart
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentralChart
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentralChart
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentralChart
     */
    'sourceIndex'?: string | null;
    /**
     * 
     * @type {Array<Series>}
     * @memberof CentralChart
     */
    'seriesElements'?: Array<Series> | null;
    /**
     * 
     * @type {Array<YAxis>}
     * @memberof CentralChart
     */
    'yAxisElements'?: Array<YAxis> | null;
    /**
     * 
     * @type {string}
     * @memberof CentralChart
     */
    'dateFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof CentralChart
     */
    'dateTo'?: string;
    /**
     * 
     * @type {number}
     * @memberof CentralChart
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof CentralChart
     */
    'currentInterval'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentralChart
     */
    'timeFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof CentralChart
     */
    'timeTo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CentralChart
     */
    'rangeChecked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CentralChart
     */
    'login'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CentralChart
     */
    'wayyUsersGroupsId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CentralChart
     */
    'dateTimeAdd'?: string;
    /**
     * 
     * @type {string}
     * @memberof CentralChart
     */
    'dateTimeUpdate'?: string;
    /**
     * 
     * @type {CentralChartEdit}
     * @memberof CentralChart
     */
    'operationEdit'?: CentralChartEdit;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CentralChartEdit = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type CentralChartEdit = typeof CentralChartEdit[keyof typeof CentralChartEdit];


/**
 * 
 * @export
 * @interface CentralParameter
 */
export interface CentralParameter {
    /**
     * 
     * @type {string}
     * @memberof CentralParameter
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentralParameter
     */
    'plcId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentralParameter
     */
    'myWayyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentralParameter
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentralParameter
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentralParameter
     */
    'plcParameterId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CentralParameter
     */
    'suffix'?: string | null;
    /**
     * 
     * @type {HmiNumberFormat}
     * @memberof CentralParameter
     */
    'format'?: HmiNumberFormat;
    /**
     * 
     * @type {string}
     * @memberof CentralParameter
     */
    'toStringFormat'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CentralParameter
     */
    'isRanges'?: boolean;
    /**
     * 
     * @type {Array<ConditionRange>}
     * @memberof CentralParameter
     */
    'ranges'?: Array<ConditionRange> | null;
    /**
     * 
     * @type {MyWayyIconTypes}
     * @memberof CentralParameter
     */
    'iconOffKind'?: MyWayyIconTypes;
    /**
     * 
     * @type {number}
     * @memberof CentralParameter
     */
    'nudDelta'?: number;
    /**
     * 
     * @type {number}
     * @memberof CentralParameter
     */
    'maxValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof CentralParameter
     */
    'minValue'?: number;
    /**
     * 
     * @type {CentralParameterType}
     * @memberof CentralParameter
     */
    'centralParameterType'?: CentralParameterType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CentralParameterType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type CentralParameterType = typeof CentralParameterType[keyof typeof CentralParameterType];


/**
 * 
 * @export
 * @interface CentralPermission
 */
export interface CentralPermission {
    /**
     * 
     * @type {string}
     * @memberof CentralPermission
     */
    'centralId'?: string | null;
}
/**
 * 
 * @export
 * @interface ChangeLogParameters
 */
export interface ChangeLogParameters {
    /**
     * 
     * @type {number}
     * @memberof ChangeLogParameters
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeLogParameters
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogParameters
     */
    'parameterName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogParameters
     */
    'centralId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogParameters
     */
    'nameCentral'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogParameters
     */
    'area'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogParameters
     */
    'valueParameter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogParameters
     */
    'valueParameterFormat'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogParameters
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogParameters
     */
    'typeApplication'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogParameters
     */
    'login'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogParameters
     */
    'dateModify'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogParameters
     */
    'dateTimeAdd'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogParameters
     */
    'dateTimeUpdate'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ChartType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type ChartType = typeof ChartType[keyof typeof ChartType];


/**
 * 
 * @export
 * @interface ConditionRange
 */
export interface ConditionRange {
    /**
     * 
     * @type {number}
     * @memberof ConditionRange
     */
    'from'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConditionRange
     */
    'to'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConditionRange
     */
    'text'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionRange
     */
    'isText'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EdmContainerElementKind = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type EdmContainerElementKind = typeof EdmContainerElementKind[keyof typeof EdmContainerElementKind];


/**
 * 
 * @export
 * @enum {string}
 */

export const EdmExpressionKind = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_21: 21,
    NUMBER_22: 22,
    NUMBER_23: 23,
    NUMBER_24: 24,
    NUMBER_25: 25
} as const;

export type EdmExpressionKind = typeof EdmExpressionKind[keyof typeof EdmExpressionKind];


/**
 * 
 * @export
 * @enum {string}
 */

export const EdmSchemaElementKind = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type EdmSchemaElementKind = typeof EdmSchemaElementKind[keyof typeof EdmSchemaElementKind];


/**
 * 
 * @export
 * @enum {string}
 */

export const EdmTypeKind = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9
} as const;

export type EdmTypeKind = typeof EdmTypeKind[keyof typeof EdmTypeKind];


/**
 * 
 * @export
 * @interface EnergyStats
 */
export interface EnergyStats {
    /**
     * 
     * @type {number}
     * @memberof EnergyStats
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyStats
     */
    'average'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyStats
     */
    'max'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyStats
     */
    'min'?: number;
}
/**
 * 
 * @export
 * @interface ExternalUrlResponse
 */
export interface ExternalUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof ExternalUrlResponse
     */
    'url'?: string | null;
    /**
     * 
     * @type {RequestConfiguration}
     * @memberof ExternalUrlResponse
     */
    'configuration'?: RequestConfiguration;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FieldName = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type FieldName = typeof FieldName[keyof typeof FieldName];


/**
 * 
 * @export
 * @interface HmiNumberFormat
 */
export interface HmiNumberFormat {
    /**
     * 
     * @type {HmiParameterRepresentation}
     * @memberof HmiNumberFormat
     */
    'representation'?: HmiParameterRepresentation;
    /**
     * 
     * @type {HmiParameterSignDisplay}
     * @memberof HmiNumberFormat
     */
    'signDisplay'?: HmiParameterSignDisplay;
    /**
     * 
     * @type {number}
     * @memberof HmiNumberFormat
     */
    'decimalDigits'?: number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const HmiParameterRepresentation = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type HmiParameterRepresentation = typeof HmiParameterRepresentation[keyof typeof HmiParameterRepresentation];


/**
 * 
 * @export
 * @enum {string}
 */

export const HmiParameterSignDisplay = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type HmiParameterSignDisplay = typeof HmiParameterSignDisplay[keyof typeof HmiParameterSignDisplay];


/**
 * 
 * @export
 * @interface IEdmEntityContainer
 */
export interface IEdmEntityContainer {
    /**
     * 
     * @type {Array<IEdmEntityContainerElement>}
     * @memberof IEdmEntityContainer
     */
    'elements'?: Array<IEdmEntityContainerElement> | null;
    /**
     * 
     * @type {EdmSchemaElementKind}
     * @memberof IEdmEntityContainer
     */
    'schemaElementKind'?: EdmSchemaElementKind;
    /**
     * 
     * @type {string}
     * @memberof IEdmEntityContainer
     */
    'namespace'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEdmEntityContainer
     */
    'name'?: string | null;
}


/**
 * 
 * @export
 * @interface IEdmEntityContainerElement
 */
export interface IEdmEntityContainerElement {
    /**
     * 
     * @type {EdmContainerElementKind}
     * @memberof IEdmEntityContainerElement
     */
    'containerElementKind'?: EdmContainerElementKind;
    /**
     * 
     * @type {IEdmEntityContainer}
     * @memberof IEdmEntityContainerElement
     */
    'container'?: IEdmEntityContainer;
    /**
     * 
     * @type {string}
     * @memberof IEdmEntityContainerElement
     */
    'name'?: string | null;
}


/**
 * 
 * @export
 * @interface IEdmExpression
 */
export interface IEdmExpression {
    /**
     * 
     * @type {EdmExpressionKind}
     * @memberof IEdmExpression
     */
    'expressionKind'?: EdmExpressionKind;
}


/**
 * 
 * @export
 * @interface IEdmModel
 */
export interface IEdmModel {
    /**
     * 
     * @type {Array<IEdmSchemaElement>}
     * @memberof IEdmModel
     */
    'schemaElements'?: Array<IEdmSchemaElement> | null;
    /**
     * 
     * @type {Array<IEdmVocabularyAnnotation>}
     * @memberof IEdmModel
     */
    'vocabularyAnnotations'?: Array<IEdmVocabularyAnnotation> | null;
    /**
     * 
     * @type {Array<IEdmModel>}
     * @memberof IEdmModel
     */
    'referencedModels'?: Array<IEdmModel> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof IEdmModel
     */
    'declaredNamespaces'?: Array<string> | null;
    /**
     * 
     * @type {object}
     * @memberof IEdmModel
     */
    'directValueAnnotationsManager'?: object;
    /**
     * 
     * @type {IEdmEntityContainer}
     * @memberof IEdmModel
     */
    'entityContainer'?: IEdmEntityContainer;
}
/**
 * 
 * @export
 * @interface IEdmSchemaElement
 */
export interface IEdmSchemaElement {
    /**
     * 
     * @type {EdmSchemaElementKind}
     * @memberof IEdmSchemaElement
     */
    'schemaElementKind'?: EdmSchemaElementKind;
    /**
     * 
     * @type {string}
     * @memberof IEdmSchemaElement
     */
    'namespace'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEdmSchemaElement
     */
    'name'?: string | null;
}


/**
 * 
 * @export
 * @interface IEdmTerm
 */
export interface IEdmTerm {
    /**
     * 
     * @type {IEdmTypeReference}
     * @memberof IEdmTerm
     */
    'type'?: IEdmTypeReference;
    /**
     * 
     * @type {string}
     * @memberof IEdmTerm
     */
    'appliesTo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEdmTerm
     */
    'defaultValue'?: string | null;
    /**
     * 
     * @type {EdmSchemaElementKind}
     * @memberof IEdmTerm
     */
    'schemaElementKind'?: EdmSchemaElementKind;
    /**
     * 
     * @type {string}
     * @memberof IEdmTerm
     */
    'namespace'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEdmTerm
     */
    'name'?: string | null;
}


/**
 * 
 * @export
 * @interface IEdmType
 */
export interface IEdmType {
    /**
     * 
     * @type {EdmTypeKind}
     * @memberof IEdmType
     */
    'typeKind'?: EdmTypeKind;
}


/**
 * 
 * @export
 * @interface IEdmTypeReference
 */
export interface IEdmTypeReference {
    /**
     * 
     * @type {boolean}
     * @memberof IEdmTypeReference
     */
    'isNullable'?: boolean;
    /**
     * 
     * @type {IEdmType}
     * @memberof IEdmTypeReference
     */
    'definition'?: IEdmType;
}
/**
 * 
 * @export
 * @interface IEdmVocabularyAnnotation
 */
export interface IEdmVocabularyAnnotation {
    /**
     * 
     * @type {string}
     * @memberof IEdmVocabularyAnnotation
     */
    'qualifier'?: string | null;
    /**
     * 
     * @type {IEdmTerm}
     * @memberof IEdmVocabularyAnnotation
     */
    'term'?: IEdmTerm;
    /**
     * 
     * @type {object}
     * @memberof IEdmVocabularyAnnotation
     */
    'target'?: object;
    /**
     * 
     * @type {IEdmExpression}
     * @memberof IEdmVocabularyAnnotation
     */
    'value'?: IEdmExpression;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InformationType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type InformationType = typeof InformationType[keyof typeof InformationType];


/**
 * 
 * @export
 * @interface InitData
 */
export interface InitData {
    /**
     * 
     * @type {WayyUser}
     * @memberof InitData
     */
    'wayyUser'?: WayyUser;
    /**
     * 
     * @type {Array<WayyArea>}
     * @memberof InitData
     */
    'areas'?: Array<WayyArea> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LoginStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6
} as const;

export type LoginStatus = typeof LoginStatus[keyof typeof LoginStatus];


/**
 * 
 * @export
 * @interface MessageAction
 */
export interface MessageAction {
    /**
     * 
     * @type {string}
     * @memberof MessageAction
     */
    'actionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageAction
     */
    'makerLogin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageAction
     */
    'makerSurname'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MessageStatus = {
    NUMBER_0: 0,
    NUMBER_2: 2
} as const;

export type MessageStatus = typeof MessageStatus[keyof typeof MessageStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const MessageType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type MessageType = typeof MessageType[keyof typeof MessageType];


/**
 * 
 * @export
 * @enum {string}
 */

export const MyWayyIconTypes = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_21: 21,
    NUMBER_22: 22,
    NUMBER_23: 23,
    NUMBER_24: 24,
    NUMBER_25: 25,
    NUMBER_26: 26,
    NUMBER_27: 27,
    NUMBER_28: 28,
    NUMBER_29: 29,
    NUMBER_30: 30,
    NUMBER_31: 31,
    NUMBER_32: 32,
    NUMBER_33: 33,
    NUMBER_34: 34,
    NUMBER_35: 35,
    NUMBER_36: 36,
    NUMBER_37: 37,
    NUMBER_38: 38,
    NUMBER_39: 39,
    NUMBER_40: 40,
    NUMBER_41: 41,
    NUMBER_42: 42,
    NUMBER_43: 43,
    NUMBER_44: 44,
    NUMBER_45: 45,
    NUMBER_46: 46
} as const;

export type MyWayyIconTypes = typeof MyWayyIconTypes[keyof typeof MyWayyIconTypes];


/**
 * 
 * @export
 * @interface ODataEntitySetInfo
 */
export interface ODataEntitySetInfo {
    /**
     * 
     * @type {ODataTypeAnnotation}
     * @memberof ODataEntitySetInfo
     */
    'typeAnnotation'?: ODataTypeAnnotation;
    /**
     * 
     * @type {string}
     * @memberof ODataEntitySetInfo
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ODataEntitySetInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ODataEntitySetInfo
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface ODataFunctionImportInfo
 */
export interface ODataFunctionImportInfo {
    /**
     * 
     * @type {ODataTypeAnnotation}
     * @memberof ODataFunctionImportInfo
     */
    'typeAnnotation'?: ODataTypeAnnotation;
    /**
     * 
     * @type {string}
     * @memberof ODataFunctionImportInfo
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ODataFunctionImportInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ODataFunctionImportInfo
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface ODataServiceDocument
 */
export interface ODataServiceDocument {
    /**
     * 
     * @type {ODataTypeAnnotation}
     * @memberof ODataServiceDocument
     */
    'typeAnnotation'?: ODataTypeAnnotation;
    /**
     * 
     * @type {Array<ODataEntitySetInfo>}
     * @memberof ODataServiceDocument
     */
    'entitySets'?: Array<ODataEntitySetInfo> | null;
    /**
     * 
     * @type {Array<ODataSingletonInfo>}
     * @memberof ODataServiceDocument
     */
    'singletons'?: Array<ODataSingletonInfo> | null;
    /**
     * 
     * @type {Array<ODataFunctionImportInfo>}
     * @memberof ODataServiceDocument
     */
    'functionImports'?: Array<ODataFunctionImportInfo> | null;
}
/**
 * 
 * @export
 * @interface ODataSingletonInfo
 */
export interface ODataSingletonInfo {
    /**
     * 
     * @type {ODataTypeAnnotation}
     * @memberof ODataSingletonInfo
     */
    'typeAnnotation'?: ODataTypeAnnotation;
    /**
     * 
     * @type {string}
     * @memberof ODataSingletonInfo
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ODataSingletonInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ODataSingletonInfo
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface ODataTypeAnnotation
 */
export interface ODataTypeAnnotation {
    /**
     * 
     * @type {string}
     * @memberof ODataTypeAnnotation
     */
    'typeName'?: string | null;
}
/**
 * 
 * @export
 * @interface ParameterInGroup
 */
export interface ParameterInGroup {
    /**
     * 
     * @type {string}
     * @memberof ParameterInGroup
     */
    'parameterId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParameterInGroup
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PlcMessageType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type PlcMessageType = typeof PlcMessageType[keyof typeof PlcMessageType];


/**
 * 
 * @export
 * @enum {string}
 */

export const PlcStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6
} as const;

export type PlcStatus = typeof PlcStatus[keyof typeof PlcStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const PvApiStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type PvApiStatus = typeof PvApiStatus[keyof typeof PvApiStatus];


/**
 * 
 * @export
 * @interface PvInfo
 */
export interface PvInfo {
    /**
     * 
     * @type {string}
     * @memberof PvInfo
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PvInfo
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface PvInstallation
 */
export interface PvInstallation {
    /**
     * 
     * @type {string}
     * @memberof PvInstallation
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PvInstallation
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PvInstallation
     */
    'image'?: string | null;
    /**
     * 
     * @type {Array<PvInfo>}
     * @memberof PvInstallation
     */
    'info'?: Array<PvInfo> | null;
    /**
     * 
     * @type {string}
     * @memberof PvInstallation
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PvInstallation
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PvInstallation
     */
    'lat'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PvInstallation
     */
    'lon'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PvInstallation
     */
    'maxPower'?: number | null;
    /**
     * 
     * @type {SourceParameterType}
     * @memberof PvInstallation
     */
    'sourceParameterType'?: SourceParameterType;
}


/**
 * 
 * @export
 * @interface PvInstallationSettings
 */
export interface PvInstallationSettings {
    /**
     * 
     * @type {string}
     * @memberof PvInstallationSettings
     */
    'area'?: string | null;
    /**
     * 
     * @type {PvInstallation}
     * @memberof PvInstallationSettings
     */
    'definition'?: PvInstallation;
    /**
     * 
     * @type {PvParameters}
     * @memberof PvInstallationSettings
     */
    'usedPower'?: PvParameters;
    /**
     * 
     * @type {PvParameters}
     * @memberof PvInstallationSettings
     */
    'generatedPower'?: PvParameters;
}
/**
 * 
 * @export
 * @interface PvParameters
 */
export interface PvParameters {
    /**
     * 
     * @type {Array<string>}
     * @memberof PvParameters
     */
    'parameters'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof PvParameters
     */
    'divider'?: number;
}
/**
 * 
 * @export
 * @interface ReadInfo
 */
export interface ReadInfo {
    /**
     * 
     * @type {XSchedule}
     * @memberof ReadInfo
     */
    'data'?: XSchedule;
    /**
     * 
     * @type {string}
     * @memberof ReadInfo
     */
    'readDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReadInfo
     */
    'readDurationMs'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadInfo
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadInfo
     */
    'assignedTemplateId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReadInfo
     */
    'isTemplateCoherent'?: boolean;
}
/**
 * 
 * @export
 * @interface RegistryEntryDefinition
 */
export interface RegistryEntryDefinition {
    /**
     * 
     * @type {string}
     * @memberof RegistryEntryDefinition
     */
    'key'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RegistryEntryDefinition
     */
    'initialValue'?: number;
}
/**
 * 
 * @export
 * @interface RequestConfiguration
 */
export interface RequestConfiguration {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RequestConfiguration
     */
    'headers'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface Route
 */
export interface Route {
    /**
     * 
     * @type {string}
     * @memberof Route
     */
    'msgId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Route
     */
    'view'?: string | null;
}
/**
 * 
 * @export
 * @interface RoutesGroup
 */
export interface RoutesGroup {
    /**
     * 
     * @type {string}
     * @memberof RoutesGroup
     */
    'plcId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoutesGroup
     */
    'default'?: string | null;
    /**
     * 
     * @type {Array<Route>}
     * @memberof RoutesGroup
     */
    'routes'?: Array<Route> | null;
}
/**
 * 
 * @export
 * @interface ScheduleTemplate
 */
export interface ScheduleTemplate {
    /**
     * 
     * @type {string}
     * @memberof ScheduleTemplate
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduleTemplate
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduleTemplate
     */
    'supportedModes'?: Array<string> | null;
    /**
     * 
     * @type {XTimeTable}
     * @memberof ScheduleTemplate
     */
    'timeTable'?: XTimeTable;
    /**
     * 
     * @type {string}
     * @memberof ScheduleTemplate
     */
    'lastModification'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SearchStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type SearchStatus = typeof SearchStatus[keyof typeof SearchStatus];


/**
 * 
 * @export
 * @interface Series
 */
export interface Series {
    /**
     * 
     * @type {number}
     * @memberof Series
     */
    'chartId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    'plcId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    'plcParameterName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Series
     */
    'scaleFactor'?: number;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    'color2'?: string | null;
    /**
     * 
     * @type {ChartType}
     * @memberof Series
     */
    'typeChart'?: ChartType;
    /**
     * 
     * @type {AggregationType}
     * @memberof Series
     */
    'typeAggregation'?: AggregationType;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Series
     */
    'baseLine'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Series
     */
    'isMarker'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Series
     */
    'markerValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    'yAxisId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    'suffix'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Series
     */
    'isValid'?: boolean;
}


/**
 * 
 * @export
 * @interface SimpleResponse
 */
export interface SimpleResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SimpleResponse
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SimpleResponse
     */
    'errorTxt'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SortOrder = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type SortOrder = typeof SortOrder[keyof typeof SortOrder];


/**
 * 
 * @export
 * @interface Sorting
 */
export interface Sorting {
    /**
     * 
     * @type {FieldName}
     * @memberof Sorting
     */
    'field'?: FieldName;
    /**
     * 
     * @type {SortOrder}
     * @memberof Sorting
     */
    'sortOrder'?: SortOrder;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SourceParameterType = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type SourceParameterType = typeof SourceParameterType[keyof typeof SourceParameterType];


/**
 * 
 * @export
 * @interface StructureNode
 */
export interface StructureNode {
    /**
     * 
     * @type {string}
     * @memberof StructureNode
     */
    'header'?: string | null;
    /**
     * 
     * @type {Array<Tile>}
     * @memberof StructureNode
     */
    'tiles'?: Array<Tile> | null;
}
/**
 * 
 * @export
 * @interface Tile
 */
export interface Tile {
    /**
     * 
     * @type {boolean}
     * @memberof Tile
     */
    'isDir'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Tile
     */
    'read'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tile
     */
    'dest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tile
     */
    'icon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tile
     */
    'label'?: string | null;
}
/**
 * 
 * @export
 * @interface ValueSetLog
 */
export interface ValueSetLog {
    /**
     * 
     * @type {number}
     * @memberof ValueSetLog
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ValueSetLog
     */
    'parameterId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValueSetLog
     */
    'deviceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValueSetLog
     */
    'valueStr'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ValueSetLog
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ValueSetLog
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValueSetLog
     */
    'dateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValueSetLog
     */
    'parameterName'?: string | null;
}
/**
 * 
 * @export
 * @interface ValuesForDate
 */
export interface ValuesForDate {
    /**
     * 
     * @type {string}
     * @memberof ValuesForDate
     */
    'date'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ValuesForDate
     */
    'values'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface ValuesForDateListPvApiResponse
 */
export interface ValuesForDateListPvApiResponse {
    /**
     * 
     * @type {PvApiStatus}
     * @memberof ValuesForDateListPvApiResponse
     */
    'status'?: PvApiStatus;
    /**
     * 
     * @type {EnergyStats}
     * @memberof ValuesForDateListPvApiResponse
     */
    'stats'?: EnergyStats;
    /**
     * 
     * @type {Array<ValuesForDate>}
     * @memberof ValuesForDateListPvApiResponse
     */
    'series'?: Array<ValuesForDate> | null;
}


/**
 * 
 * @export
 * @interface WayyArea
 */
export interface WayyArea {
    /**
     * 
     * @type {string}
     * @memberof WayyArea
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyArea
     */
    'prefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyArea
     */
    'mwy2'?: string | null;
    /**
     * 
     * @type {Array<CentralParameter>}
     * @memberof WayyArea
     */
    'additionalParameters'?: Array<CentralParameter> | null;
    /**
     * 
     * @type {boolean}
     * @memberof WayyArea
     */
    'isPvEnabled'?: boolean;
    /**
     * 
     * @type {Array<PvInstallationSettings>}
     * @memberof WayyArea
     */
    'pvInstallations'?: Array<PvInstallationSettings> | null;
    /**
     * 
     * @type {{ [key: string]: Array<ParameterInGroup>; }}
     * @memberof WayyArea
     */
    'parametersGroups2'?: { [key: string]: Array<ParameterInGroup>; } | null;
    /**
     * 
     * @type {Array<RegistryEntryDefinition>}
     * @memberof WayyArea
     */
    'registryInitials'?: Array<RegistryEntryDefinition> | null;
}
/**
 * 
 * @export
 * @interface WayyChart2
 */
export interface WayyChart2 {
    /**
     * 
     * @type {string}
     * @memberof WayyChart2
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<WayyChartSeries>}
     * @memberof WayyChart2
     */
    'parameters'?: Array<WayyChartSeries> | null;
    /**
     * 
     * @type {WayyChartType}
     * @memberof WayyChart2
     */
    'type'?: WayyChartType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const WayyChartParameterType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type WayyChartParameterType = typeof WayyChartParameterType[keyof typeof WayyChartParameterType];


/**
 * 
 * @export
 * @interface WayyChartSeries
 */
export interface WayyChartSeries {
    /**
     * 
     * @type {WayyChartParameterType}
     * @memberof WayyChartSeries
     */
    'type'?: WayyChartParameterType;
    /**
     * 
     * @type {string}
     * @memberof WayyChartSeries
     */
    'parameterId'?: string | null;
    /**
     * 
     * @type {WayyChartType}
     * @memberof WayyChartSeries
     */
    'chartType'?: WayyChartType;
    /**
     * 
     * @type {string}
     * @memberof WayyChartSeries
     */
    'color'?: string | null;
}


/**
 * 
 * @export
 * @interface WayyChartSet
 */
export interface WayyChartSet {
    /**
     * 
     * @type {string}
     * @memberof WayyChartSet
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<WayyChart2>}
     * @memberof WayyChartSet
     */
    'charts'?: Array<WayyChart2> | null;
    /**
     * 
     * @type {string}
     * @memberof WayyChartSet
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyChartSet
     */
    'id'?: string | null;
    /**
     * 
     * @type {WayyChartSetType}
     * @memberof WayyChartSet
     */
    'chartSetType'?: WayyChartSetType;
    /**
     * 
     * @type {string}
     * @memberof WayyChartSet
     */
    'grafanaDashboardId'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const WayyChartSetType = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type WayyChartSetType = typeof WayyChartSetType[keyof typeof WayyChartSetType];


/**
 * 
 * @export
 * @enum {string}
 */

export const WayyChartType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type WayyChartType = typeof WayyChartType[keyof typeof WayyChartType];


/**
 * 
 * @export
 * @interface WayyMessage
 */
export interface WayyMessage {
    /**
     * 
     * @type {string}
     * @memberof WayyMessage
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyMessage
     */
    'centralId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyMessage
     */
    'area'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyMessage
     */
    'messageId'?: string | null;
    /**
     * 
     * @type {MessageType}
     * @memberof WayyMessage
     */
    'type'?: MessageType;
    /**
     * 
     * @type {string}
     * @memberof WayyMessage
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyMessage
     */
    'textShort'?: string | null;
    /**
     * 
     * @type {MessageStatus}
     * @memberof WayyMessage
     */
    'messageStatus'?: MessageStatus;
    /**
     * 
     * @type {InformationType}
     * @memberof WayyMessage
     */
    'informationStatus'?: InformationType;
    /**
     * 
     * @type {string}
     * @memberof WayyMessage
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof WayyMessage
     */
    'closeDate'?: string | null;
    /**
     * 
     * @type {Array<MessageAction>}
     * @memberof WayyMessage
     */
    'resets'?: Array<MessageAction> | null;
    /**
     * 
     * @type {Array<MessageAction>}
     * @memberof WayyMessage
     */
    'confirmations'?: Array<MessageAction> | null;
    /**
     * 
     * @type {boolean}
     * @memberof WayyMessage
     */
    'isReset'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WayyMessage
     */
    'nameCentral'?: string | null;
    /**
     * 
     * @type {CentralAlert}
     * @memberof WayyMessage
     */
    'alertDefinition'?: CentralAlert;
}


/**
 * 
 * @export
 * @interface WayyMessageCriteria
 */
export interface WayyMessageCriteria {
    /**
     * 
     * @type {number}
     * @memberof WayyMessageCriteria
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof WayyMessageCriteria
     */
    'pageNo'?: number;
    /**
     * 
     * @type {string}
     * @memberof WayyMessageCriteria
     */
    'dateFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof WayyMessageCriteria
     */
    'dateTo'?: string;
    /**
     * 
     * @type {Array<FieldName>}
     * @memberof WayyMessageCriteria
     */
    'dateFilters'?: Array<FieldName> | null;
    /**
     * 
     * @type {Array<MessageType>}
     * @memberof WayyMessageCriteria
     */
    'messageTypeFilters'?: Array<MessageType> | null;
    /**
     * 
     * @type {Array<MessageStatus>}
     * @memberof WayyMessageCriteria
     */
    'messageStatusFilters'?: Array<MessageStatus> | null;
    /**
     * 
     * @type {Array<InformationType>}
     * @memberof WayyMessageCriteria
     */
    'informationTypeFilters'?: Array<InformationType> | null;
    /**
     * 
     * @type {Array<Sorting>}
     * @memberof WayyMessageCriteria
     */
    'sort'?: Array<Sorting> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WayyMessageCriteria
     */
    'centralId'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof WayyMessageCriteria
     */
    'textSearch'?: string | null;
}
/**
 * 
 * @export
 * @interface WayyMessageSearchResult
 */
export interface WayyMessageSearchResult {
    /**
     * 
     * @type {SearchStatus}
     * @memberof WayyMessageSearchResult
     */
    'status'?: SearchStatus;
    /**
     * 
     * @type {number}
     * @memberof WayyMessageSearchResult
     */
    'totalDocuments'?: number;
    /**
     * 
     * @type {number}
     * @memberof WayyMessageSearchResult
     */
    'requestedPage'?: number;
    /**
     * 
     * @type {number}
     * @memberof WayyMessageSearchResult
     */
    'requestedPageSize'?: number;
    /**
     * 
     * @type {Array<WayyMessage>}
     * @memberof WayyMessageSearchResult
     */
    'data'?: Array<WayyMessage> | null;
}


/**
 * 
 * @export
 * @interface WayyNote
 */
export interface WayyNote {
    /**
     * 
     * @type {number}
     * @memberof WayyNote
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WayyNote
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof WayyNote
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyNote
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyNote
     */
    'header'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyNote
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyNote
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyNote
     */
    'dateTimeAdd'?: string;
    /**
     * 
     * @type {string}
     * @memberof WayyNote
     */
    'dateTimeUpdate'?: string;
    /**
     * 
     * @type {number}
     * @memberof WayyNote
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof WayyNote
     */
    'x'?: number;
}
/**
 * 
 * @export
 * @interface WayyUser
 */
export interface WayyUser {
    /**
     * 
     * @type {number}
     * @memberof WayyUser
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WayyUser
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyUser
     */
    'surname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyUser
     */
    'caption'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyUser
     */
    'login'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyUser
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyUser
     */
    'passwordHash'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyUser
     */
    'email'?: string | null;
    /**
     * 
     * @type {LoginStatus}
     * @memberof WayyUser
     */
    'status'?: LoginStatus;
    /**
     * 
     * @type {AccountType}
     * @memberof WayyUser
     */
    'accountTypeObject'?: AccountType;
    /**
     * 
     * @type {AccountStatus}
     * @memberof WayyUser
     */
    'accountStatusObject'?: AccountStatus;
    /**
     * 
     * @type {boolean}
     * @memberof WayyUser
     */
    'isShowAlert'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WayyUser
     */
    'isShowWarning'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WayyUser
     */
    'userAdd'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyUser
     */
    'loginAdd'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WayyUser
     */
    'alarmsTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof WayyUser
     */
    'warningsTime'?: number;
    /**
     * 
     * @type {string}
     * @memberof WayyUser
     */
    'oldPassword'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WayyUser
     */
    'wayyUsersGroupsId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WayyUser
     */
    'showMiniatureDashboard'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WayyUser
     */
    'miniatureIdDashboard'?: number;
    /**
     * 
     * @type {number}
     * @memberof WayyUser
     */
    'miniatureIdSchedule'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WayyUser
     */
    'showMiniatureChart'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WayyUser
     */
    'miniatureIdChart'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WayyUser
     */
    'toolbarMinimize'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WayyUser
     */
    'askCloseApplication'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WayyUser
     */
    'dateTimeAdd'?: string;
    /**
     * 
     * @type {string}
     * @memberof WayyUser
     */
    'dateTimeUpdate'?: string;
    /**
     * 
     * @type {WayyUserGroup}
     * @memberof WayyUser
     */
    'wayyUserGroupObject'?: WayyUserGroup;
}


/**
 * 
 * @export
 * @interface WayyUserGroup
 */
export interface WayyUserGroup {
    /**
     * 
     * @type {number}
     * @memberof WayyUserGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WayyUserGroup
     */
    'nameGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyUserGroup
     */
    'descriptions'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyUserGroup
     */
    'dateTimeAdd'?: string;
    /**
     * 
     * @type {string}
     * @memberof WayyUserGroup
     */
    'dateTimeUpdate'?: string;
    /**
     * 
     * @type {Array<CentralPermission>}
     * @memberof WayyUserGroup
     */
    'centralsListView'?: Array<CentralPermission> | null;
    /**
     * 
     * @type {string}
     * @memberof WayyUserGroup
     */
    'userName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WayyUserGroup
     */
    'userId'?: number;
}
/**
 * 
 * @export
 * @interface WayyUserLog
 */
export interface WayyUserLog {
    /**
     * 
     * @type {number}
     * @memberof WayyUserLog
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof WayyUserLog
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof WayyUserLog
     */
    'loginLog'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyUserLog
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyUserLog
     */
    'ipAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyUserLog
     */
    'nameMachine'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyUserLog
     */
    'versionApplication'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WayyUserLog
     */
    'typeApplication'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WayyUserLog
     */
    'wayyUsersGroupsId'?: number;
    /**
     * 
     * @type {string}
     * @memberof WayyUserLog
     */
    'dateTimeAdd'?: string;
    /**
     * 
     * @type {string}
     * @memberof WayyUserLog
     */
    'dateTimeUpdate'?: string;
    /**
     * 
     * @type {string}
     * @memberof WayyUserLog
     */
    'dateCreationLog'?: string;
}
/**
 * 
 * @export
 * @interface XDaySchedule
 */
export interface XDaySchedule {
    /**
     * 
     * @type {Array<XDayScope>}
     * @memberof XDaySchedule
     */
    'scopes'?: Array<XDayScope> | null;
}
/**
 * 
 * @export
 * @interface XDayScope
 */
export interface XDayScope {
    /**
     * 
     * @type {string}
     * @memberof XDayScope
     */
    'modeName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof XDayScope
     */
    'endingHour'?: number;
    /**
     * 
     * @type {number}
     * @memberof XDayScope
     */
    'endingMinute'?: number;
}
/**
 * 
 * @export
 * @interface XSchedule
 */
export interface XSchedule {
    /**
     * 
     * @type {string}
     * @memberof XSchedule
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XSchedule
     */
    'plcId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XSchedule
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof XSchedule
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {Array<XScheduleMode>}
     * @memberof XSchedule
     */
    'modes'?: Array<XScheduleMode> | null;
    /**
     * 
     * @type {XTimeTable}
     * @memberof XSchedule
     */
    'timeTable'?: XTimeTable;
    /**
     * 
     * @type {Array<string>}
     * @memberof XSchedule
     */
    'parameterNames'?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof XSchedule
     */
    'parameterIndexes'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface XScheduleMode
 */
export interface XScheduleMode {
    /**
     * 
     * @type {number}
     * @memberof XScheduleMode
     */
    'id'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof XScheduleMode
     */
    'values'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof XScheduleMode
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface XSchedulerDateException
 */
export interface XSchedulerDateException {
    /**
     * 
     * @type {string}
     * @memberof XSchedulerDateException
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof XSchedulerDateException
     */
    'endDate'?: string;
    /**
     * 
     * @type {XDaySchedule}
     * @memberof XSchedulerDateException
     */
    'schedule'?: XDaySchedule;
}
/**
 * 
 * @export
 * @interface XTimeTable
 */
export interface XTimeTable {
    /**
     * 
     * @type {XDaySchedule}
     * @memberof XTimeTable
     */
    'monday'?: XDaySchedule;
    /**
     * 
     * @type {XDaySchedule}
     * @memberof XTimeTable
     */
    'tuesday'?: XDaySchedule;
    /**
     * 
     * @type {XDaySchedule}
     * @memberof XTimeTable
     */
    'wednesday'?: XDaySchedule;
    /**
     * 
     * @type {XDaySchedule}
     * @memberof XTimeTable
     */
    'thursday'?: XDaySchedule;
    /**
     * 
     * @type {XDaySchedule}
     * @memberof XTimeTable
     */
    'friday'?: XDaySchedule;
    /**
     * 
     * @type {XDaySchedule}
     * @memberof XTimeTable
     */
    'saturday'?: XDaySchedule;
    /**
     * 
     * @type {XDaySchedule}
     * @memberof XTimeTable
     */
    'sunday'?: XDaySchedule;
    /**
     * 
     * @type {XDaySchedule}
     * @memberof XTimeTable
     */
    'holidaySchedule'?: XDaySchedule;
    /**
     * 
     * @type {Array<XSchedulerDateException>}
     * @memberof XTimeTable
     */
    'exceptions'?: Array<XSchedulerDateException> | null;
}
/**
 * 
 * @export
 * @interface YAxis
 */
export interface YAxis {
    /**
     * 
     * @type {string}
     * @memberof YAxis
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof YAxis
     */
    'chartId'?: number;
    /**
     * 
     * @type {string}
     * @memberof YAxis
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof YAxis
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof YAxis
     */
    'interval'?: number;
}

/**
 * CentralApi - axios parameter creator
 * @export
 */
export const CentralApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCentralGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Central`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CentralApi - functional programming interface
 * @export
 */
export const CentralApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CentralApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCentralGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Central>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCentralGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CentralApi - factory interface
 * @export
 */
export const CentralApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CentralApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCentralGet(options?: any): AxiosPromise<Array<Central>> {
            return localVarFp.apiCentralGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CentralApi - object-oriented interface
 * @export
 * @class CentralApi
 * @extends {BaseAPI}
 */
export class CentralApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CentralApi
     */
    public apiCentralGet(options?: AxiosRequestConfig) {
        return CentralApiFp(this.configuration).apiCentralGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChartApi - axios parameter creator
 * @export
 */
export const ChartApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChartDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Chart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WayyChartSet} [wayyChartSet] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChartPost: async (wayyChartSet?: WayyChartSet, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Chart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wayyChartSet, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChartApi - functional programming interface
 * @export
 */
export const ChartApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChartApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiChartDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiChartDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WayyChartSet} [wayyChartSet] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiChartPost(wayyChartSet?: WayyChartSet, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiChartPost(wayyChartSet, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChartApi - factory interface
 * @export
 */
export const ChartApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChartApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChartDelete(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiChartDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WayyChartSet} [wayyChartSet] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChartPost(wayyChartSet?: WayyChartSet, options?: any): AxiosPromise<void> {
            return localVarFp.apiChartPost(wayyChartSet, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChartApi - object-oriented interface
 * @export
 * @class ChartApi
 * @extends {BaseAPI}
 */
export class ChartApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartApi
     */
    public apiChartDelete(id?: string, options?: AxiosRequestConfig) {
        return ChartApiFp(this.configuration).apiChartDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WayyChartSet} [wayyChartSet] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartApi
     */
    public apiChartPost(wayyChartSet?: WayyChartSet, options?: AxiosRequestConfig) {
        return ChartApiFp(this.configuration).apiChartPost(wayyChartSet, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EnergyUsageApi - axios parameter creator
 * @export
 */
export const EnergyUsageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [aggregationType] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEnergyUsageCsvGet: async (aggregationType?: string, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EnergyUsage/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (aggregationType !== undefined) {
                localVarQueryParameter['aggregationType'] = aggregationType;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnergyUsageApi - functional programming interface
 * @export
 */
export const EnergyUsageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnergyUsageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [aggregationType] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEnergyUsageCsvGet(aggregationType?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEnergyUsageCsvGet(aggregationType, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnergyUsageApi - factory interface
 * @export
 */
export const EnergyUsageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnergyUsageApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [aggregationType] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEnergyUsageCsvGet(aggregationType?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiEnergyUsageCsvGet(aggregationType, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnergyUsageApi - object-oriented interface
 * @export
 * @class EnergyUsageApi
 * @extends {BaseAPI}
 */
export class EnergyUsageApi extends BaseAPI {
    /**
     * 
     * @param {string} [aggregationType] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergyUsageApi
     */
    public apiEnergyUsageCsvGet(aggregationType?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return EnergyUsageApiFp(this.configuration).apiEnergyUsageCsvGet(aggregationType, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileHashApi - axios parameter creator
 * @export
 */
export const FileHashApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileHashApiFileHashFileNameGet: async (fileName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('apiFileHashApiFileHashFileNameGet', 'fileName', fileName)
            const localVarPath = `/api/FileHash/api/FileHash/{fileName}`
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileHashGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FileHash`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileHashApi - functional programming interface
 * @export
 */
export const FileHashApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileHashApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileHashApiFileHashFileNameGet(fileName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileHashApiFileHashFileNameGet(fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileHashGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileHashGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileHashApi - factory interface
 * @export
 */
export const FileHashApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileHashApiFp(configuration)
    return {
        /**
         * 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileHashApiFileHashFileNameGet(fileName: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiFileHashApiFileHashFileNameGet(fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileHashGet(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.apiFileHashGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileHashApi - object-oriented interface
 * @export
 * @class FileHashApi
 * @extends {BaseAPI}
 */
export class FileHashApi extends BaseAPI {
    /**
     * 
     * @param {string} fileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileHashApi
     */
    public apiFileHashApiFileHashFileNameGet(fileName: string, options?: AxiosRequestConfig) {
        return FileHashApiFp(this.configuration).apiFileHashApiFileHashFileNameGet(fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileHashApi
     */
    public apiFileHashGet(options?: AxiosRequestConfig) {
        return FileHashApiFp(this.configuration).apiFileHashGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InitApi - axios parameter creator
 * @export
 */
export const InitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [login] 
         * @param {string} [pass] 
         * @param {string} [ipAddress] 
         * @param {string} [machineName] 
         * @param {string} [versionApp] 
         * @param {string} [typeApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInitGet: async (login?: string, pass?: string, ipAddress?: string, machineName?: string, versionApp?: string, typeApplication?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Init`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (login !== undefined) {
                localVarQueryParameter['login'] = login;
            }

            if (pass !== undefined) {
                localVarQueryParameter['pass'] = pass;
            }

            if (ipAddress !== undefined) {
                localVarQueryParameter['ipAddress'] = ipAddress;
            }

            if (machineName !== undefined) {
                localVarQueryParameter['machineName'] = machineName;
            }

            if (versionApp !== undefined) {
                localVarQueryParameter['versionApp'] = versionApp;
            }

            if (typeApplication !== undefined) {
                localVarQueryParameter['typeApplication'] = typeApplication;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InitApi - functional programming interface
 * @export
 */
export const InitApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InitApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [login] 
         * @param {string} [pass] 
         * @param {string} [ipAddress] 
         * @param {string} [machineName] 
         * @param {string} [versionApp] 
         * @param {string} [typeApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInitGet(login?: string, pass?: string, ipAddress?: string, machineName?: string, versionApp?: string, typeApplication?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInitGet(login, pass, ipAddress, machineName, versionApp, typeApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InitApi - factory interface
 * @export
 */
export const InitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InitApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [login] 
         * @param {string} [pass] 
         * @param {string} [ipAddress] 
         * @param {string} [machineName] 
         * @param {string} [versionApp] 
         * @param {string} [typeApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInitGet(login?: string, pass?: string, ipAddress?: string, machineName?: string, versionApp?: string, typeApplication?: string, options?: any): AxiosPromise<InitData> {
            return localVarFp.apiInitGet(login, pass, ipAddress, machineName, versionApp, typeApplication, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InitApi - object-oriented interface
 * @export
 * @class InitApi
 * @extends {BaseAPI}
 */
export class InitApi extends BaseAPI {
    /**
     * 
     * @param {string} [login] 
     * @param {string} [pass] 
     * @param {string} [ipAddress] 
     * @param {string} [machineName] 
     * @param {string} [versionApp] 
     * @param {string} [typeApplication] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InitApi
     */
    public apiInitGet(login?: string, pass?: string, ipAddress?: string, machineName?: string, versionApp?: string, typeApplication?: string, options?: AxiosRequestConfig) {
        return InitApiFp(this.configuration).apiInitGet(login, pass, ipAddress, machineName, versionApp, typeApplication, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MessageConfirmationApi - axios parameter creator
 * @export
 */
export const MessageConfirmationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [messageId] 
         * @param {boolean} [reset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageConfirmationPost: async (messageId?: string, reset?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MessageConfirmation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (messageId !== undefined) {
                localVarQueryParameter['messageId'] = messageId;
            }

            if (reset !== undefined) {
                localVarQueryParameter['reset'] = reset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageConfirmationApi - functional programming interface
 * @export
 */
export const MessageConfirmationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessageConfirmationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [messageId] 
         * @param {boolean} [reset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessageConfirmationPost(messageId?: string, reset?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessageConfirmationPost(messageId, reset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessageConfirmationApi - factory interface
 * @export
 */
export const MessageConfirmationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessageConfirmationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [messageId] 
         * @param {boolean} [reset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageConfirmationPost(messageId?: string, reset?: boolean, options?: any): AxiosPromise<SimpleResponse> {
            return localVarFp.apiMessageConfirmationPost(messageId, reset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessageConfirmationApi - object-oriented interface
 * @export
 * @class MessageConfirmationApi
 * @extends {BaseAPI}
 */
export class MessageConfirmationApi extends BaseAPI {
    /**
     * 
     * @param {string} [messageId] 
     * @param {boolean} [reset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageConfirmationApi
     */
    public apiMessageConfirmationPost(messageId?: string, reset?: boolean, options?: AxiosRequestConfig) {
        return MessageConfirmationApiFp(this.configuration).apiMessageConfirmationPost(messageId, reset, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MetadataApi - axios parameter creator
 * @export
 */
export const MetadataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOdata: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/odata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOdataMetadata: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/odata/$metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetadataApi - functional programming interface
 * @export
 */
export const MetadataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetadataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOdata(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ODataServiceDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOdata(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOdataMetadata(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEdmModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOdataMetadata(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MetadataApi - factory interface
 * @export
 */
export const MetadataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetadataApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOdata(options?: any): AxiosPromise<ODataServiceDocument> {
            return localVarFp.apiOdata(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOdataMetadata(options?: any): AxiosPromise<IEdmModel> {
            return localVarFp.apiOdataMetadata(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetadataApi - object-oriented interface
 * @export
 * @class MetadataApi
 * @extends {BaseAPI}
 */
export class MetadataApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataApi
     */
    public apiOdata(options?: AxiosRequestConfig) {
        return MetadataApiFp(this.configuration).apiOdata(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataApi
     */
    public apiOdataMetadata(options?: AxiosRequestConfig) {
        return MetadataApiFp(this.configuration).apiOdataMetadata(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MobileViewApi - axios parameter creator
 * @export
 */
export const MobileViewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMobileViewGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MobileView`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileViewApi - functional programming interface
 * @export
 */
export const MobileViewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileViewApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMobileViewGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: StructureNode; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMobileViewGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MobileViewApi - factory interface
 * @export
 */
export const MobileViewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileViewApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMobileViewGet(options?: any): AxiosPromise<{ [key: string]: StructureNode; }> {
            return localVarFp.apiMobileViewGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MobileViewApi - object-oriented interface
 * @export
 * @class MobileViewApi
 * @extends {BaseAPI}
 */
export class MobileViewApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileViewApi
     */
    public apiMobileViewGet(options?: AxiosRequestConfig) {
        return MobileViewApiFp(this.configuration).apiMobileViewGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotesApi - axios parameter creator
 * @export
 */
export const NotesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotesGet: async (userId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNotesIdDelete', 'id', id)
            const localVarPath = `/api/Notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WayyNote} [wayyNote] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotesPost: async (wayyNote?: WayyNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wayyNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotesApi - functional programming interface
 * @export
 */
export const NotesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotesGet(userId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WayyNote>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotesGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WayyNote} [wayyNote] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotesPost(wayyNote?: WayyNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotesPost(wayyNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotesApi - factory interface
 * @export
 */
export const NotesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotesGet(userId?: string, options?: any): AxiosPromise<Array<WayyNote>> {
            return localVarFp.apiNotesGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotesIdDelete(id: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiNotesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WayyNote} [wayyNote] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotesPost(wayyNote?: WayyNote, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiNotesPost(wayyNote, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotesApi - object-oriented interface
 * @export
 * @class NotesApi
 * @extends {BaseAPI}
 */
export class NotesApi extends BaseAPI {
    /**
     * 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public apiNotesGet(userId?: string, options?: AxiosRequestConfig) {
        return NotesApiFp(this.configuration).apiNotesGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public apiNotesIdDelete(id: number, options?: AxiosRequestConfig) {
        return NotesApiFp(this.configuration).apiNotesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WayyNote} [wayyNote] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public apiNotesPost(wayyNote?: WayyNote, options?: AxiosRequestConfig) {
        return NotesApiFp(this.configuration).apiNotesPost(wayyNote, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PVApi - axios parameter creator
 * @export
 */
export const PVApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} installationId 
         * @param {string} [type] 
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {number} [day] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPVApiPVInstallationIdGet: async (installationId: string, type?: string, year?: number, month?: number, day?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'installationId' is not null or undefined
            assertParamExists('apiPVApiPVInstallationIdGet', 'installationId', installationId)
            const localVarPath = `/api/PV/api/PV/{installationId}`
                .replace(`{${"installationId"}}`, encodeURIComponent(String(installationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (day !== undefined) {
                localVarQueryParameter['day'] = day;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPVGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PV`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PVApi - functional programming interface
 * @export
 */
export const PVApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PVApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} installationId 
         * @param {string} [type] 
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {number} [day] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPVApiPVInstallationIdGet(installationId: string, type?: string, year?: number, month?: number, day?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValuesForDateListPvApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPVApiPVInstallationIdGet(installationId, type, year, month, day, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPVGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PvInstallation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPVGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PVApi - factory interface
 * @export
 */
export const PVApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PVApiFp(configuration)
    return {
        /**
         * 
         * @param {string} installationId 
         * @param {string} [type] 
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {number} [day] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPVApiPVInstallationIdGet(installationId: string, type?: string, year?: number, month?: number, day?: number, options?: any): AxiosPromise<ValuesForDateListPvApiResponse> {
            return localVarFp.apiPVApiPVInstallationIdGet(installationId, type, year, month, day, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPVGet(options?: any): AxiosPromise<Array<PvInstallation>> {
            return localVarFp.apiPVGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PVApi - object-oriented interface
 * @export
 * @class PVApi
 * @extends {BaseAPI}
 */
export class PVApi extends BaseAPI {
    /**
     * 
     * @param {string} installationId 
     * @param {string} [type] 
     * @param {number} [year] 
     * @param {number} [month] 
     * @param {number} [day] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVApi
     */
    public apiPVApiPVInstallationIdGet(installationId: string, type?: string, year?: number, month?: number, day?: number, options?: AxiosRequestConfig) {
        return PVApiFp(this.configuration).apiPVApiPVInstallationIdGet(installationId, type, year, month, day, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVApi
     */
    public apiPVGet(options?: AxiosRequestConfig) {
        return PVApiFp(this.configuration).apiPVGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ParametersChangesApi - axios parameter creator
 * @export
 */
export const ParametersChangesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiParametersChangesApiParametersChangesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ParametersChanges/api/ParametersChanges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiParametersChangesDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ParametersChanges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiParametersChangesPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ParametersChanges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParametersChangesApi - functional programming interface
 * @export
 */
export const ParametersChangesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParametersChangesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiParametersChangesApiParametersChangesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChangeLogParameters>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiParametersChangesApiParametersChangesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiParametersChangesDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiParametersChangesDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiParametersChangesPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiParametersChangesPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ParametersChangesApi - factory interface
 * @export
 */
export const ParametersChangesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParametersChangesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiParametersChangesApiParametersChangesGet(options?: any): AxiosPromise<Array<ChangeLogParameters>> {
            return localVarFp.apiParametersChangesApiParametersChangesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiParametersChangesDelete(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiParametersChangesDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiParametersChangesPost(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiParametersChangesPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParametersChangesApi - object-oriented interface
 * @export
 * @class ParametersChangesApi
 * @extends {BaseAPI}
 */
export class ParametersChangesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersChangesApi
     */
    public apiParametersChangesApiParametersChangesGet(options?: AxiosRequestConfig) {
        return ParametersChangesApiFp(this.configuration).apiParametersChangesApiParametersChangesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersChangesApi
     */
    public apiParametersChangesDelete(id?: string, options?: AxiosRequestConfig) {
        return ParametersChangesApiFp(this.configuration).apiParametersChangesDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersChangesApi
     */
    public apiParametersChangesPost(options?: AxiosRequestConfig) {
        return ParametersChangesApiFp(this.configuration).apiParametersChangesPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlcParameter2Api - axios parameter creator
 * @export
 */
export const PlcParameter2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlcParameter2Post: async (id?: string, value?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PlcParameter2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlcParameter2Api - functional programming interface
 * @export
 */
export const PlcParameter2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlcParameter2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPlcParameter2Post(id?: string, value?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPlcParameter2Post(id, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlcParameter2Api - factory interface
 * @export
 */
export const PlcParameter2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlcParameter2ApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlcParameter2Post(id?: string, value?: string, options?: any): AxiosPromise<SimpleResponse> {
            return localVarFp.apiPlcParameter2Post(id, value, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlcParameter2Api - object-oriented interface
 * @export
 * @class PlcParameter2Api
 * @extends {BaseAPI}
 */
export class PlcParameter2Api extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {string} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlcParameter2Api
     */
    public apiPlcParameter2Post(id?: string, value?: string, options?: AxiosRequestConfig) {
        return PlcParameter2ApiFp(this.configuration).apiPlcParameter2Post(id, value, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SchedulerReadInfoApi - axios parameter creator
 * @export
 */
export const SchedulerReadInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSchedulerReadInfoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SchedulerReadInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SchedulerReadInfoApi - functional programming interface
 * @export
 */
export const SchedulerReadInfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SchedulerReadInfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSchedulerReadInfoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReadInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSchedulerReadInfoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SchedulerReadInfoApi - factory interface
 * @export
 */
export const SchedulerReadInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SchedulerReadInfoApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSchedulerReadInfoGet(options?: any): AxiosPromise<Array<ReadInfo>> {
            return localVarFp.apiSchedulerReadInfoGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SchedulerReadInfoApi - object-oriented interface
 * @export
 * @class SchedulerReadInfoApi
 * @extends {BaseAPI}
 */
export class SchedulerReadInfoApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerReadInfoApi
     */
    public apiSchedulerReadInfoGet(options?: AxiosRequestConfig) {
        return SchedulerReadInfoApiFp(this.configuration).apiSchedulerReadInfoGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SchedulerTemplatesApi - axios parameter creator
 * @export
 */
export const SchedulerTemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSchedulerTemplatesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SchedulerTemplates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SchedulerTemplatesApi - functional programming interface
 * @export
 */
export const SchedulerTemplatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SchedulerTemplatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSchedulerTemplatesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduleTemplate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSchedulerTemplatesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SchedulerTemplatesApi - factory interface
 * @export
 */
export const SchedulerTemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SchedulerTemplatesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSchedulerTemplatesGet(options?: any): AxiosPromise<Array<ScheduleTemplate>> {
            return localVarFp.apiSchedulerTemplatesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SchedulerTemplatesApi - object-oriented interface
 * @export
 * @class SchedulerTemplatesApi
 * @extends {BaseAPI}
 */
export class SchedulerTemplatesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerTemplatesApi
     */
    public apiSchedulerTemplatesGet(options?: AxiosRequestConfig) {
        return SchedulerTemplatesApiFp(this.configuration).apiSchedulerTemplatesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestApi - axios parameter creator
 * @export
 */
export const TestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTestGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTestGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTestGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTestGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiTestGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiTestGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiTestGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [login] 
         * @param {string} [pass] 
         * @param {string} [ipAddress] 
         * @param {string} [machineName] 
         * @param {string} [versionApp] 
         * @param {string} [typeApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet: async (login?: string, pass?: string, ipAddress?: string, machineName?: string, versionApp?: string, typeApplication?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (login !== undefined) {
                localVarQueryParameter['login'] = login;
            }

            if (pass !== undefined) {
                localVarQueryParameter['pass'] = pass;
            }

            if (ipAddress !== undefined) {
                localVarQueryParameter['ipAddress'] = ipAddress;
            }

            if (machineName !== undefined) {
                localVarQueryParameter['machineName'] = machineName;
            }

            if (versionApp !== undefined) {
                localVarQueryParameter['versionApp'] = versionApp;
            }

            if (typeApplication !== undefined) {
                localVarQueryParameter['typeApplication'] = typeApplication;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WayyUser} [wayyUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost: async (wayyUser?: WayyUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wayyUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAllGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WayyUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAllGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [login] 
         * @param {string} [pass] 
         * @param {string} [ipAddress] 
         * @param {string} [machineName] 
         * @param {string} [versionApp] 
         * @param {string} [typeApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGet(login?: string, pass?: string, ipAddress?: string, machineName?: string, versionApp?: string, typeApplication?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WayyUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGet(login, pass, ipAddress, machineName, versionApp, typeApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WayyUser} [wayyUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserPost(wayyUser?: WayyUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserPost(wayyUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAllGet(options?: any): AxiosPromise<Array<WayyUser>> {
            return localVarFp.apiUserAllGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDelete(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiUserDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [login] 
         * @param {string} [pass] 
         * @param {string} [ipAddress] 
         * @param {string} [machineName] 
         * @param {string} [versionApp] 
         * @param {string} [typeApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet(login?: string, pass?: string, ipAddress?: string, machineName?: string, versionApp?: string, typeApplication?: string, options?: any): AxiosPromise<WayyUser> {
            return localVarFp.apiUserGet(login, pass, ipAddress, machineName, versionApp, typeApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WayyUser} [wayyUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost(wayyUser?: WayyUser, options?: any): AxiosPromise<number> {
            return localVarFp.apiUserPost(wayyUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserAllGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserAllGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserDelete(id?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [login] 
     * @param {string} [pass] 
     * @param {string} [ipAddress] 
     * @param {string} [machineName] 
     * @param {string} [versionApp] 
     * @param {string} [typeApplication] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGet(login?: string, pass?: string, ipAddress?: string, machineName?: string, versionApp?: string, typeApplication?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGet(login, pass, ipAddress, machineName, versionApp, typeApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WayyUser} [wayyUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserPost(wayyUser?: WayyUser, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserPost(wayyUser, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserGroupApi - axios parameter creator
 * @export
 */
export const UserGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGroupDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/UserGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} act 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGroupGet: async (act: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'act' is not null or undefined
            assertParamExists('apiUserGroupGet', 'act', act)
            const localVarPath = `/api/UserGroup`
                .replace(`{${"act"}}`, encodeURIComponent(String(act)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WayyUserGroup} [wayyUserGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGroupPost: async (wayyUserGroup?: WayyUserGroup, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/UserGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wayyUserGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserGroupApi - functional programming interface
 * @export
 */
export const UserGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGroupDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGroupDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} act 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGroupGet(act: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WayyUserGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGroupGet(act, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WayyUserGroup} [wayyUserGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGroupPost(wayyUserGroup?: WayyUserGroup, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGroupPost(wayyUserGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserGroupApi - factory interface
 * @export
 */
export const UserGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserGroupApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGroupDelete(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiUserGroupDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} act 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGroupGet(act: string, options?: any): AxiosPromise<Array<WayyUserGroup>> {
            return localVarFp.apiUserGroupGet(act, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WayyUserGroup} [wayyUserGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGroupPost(wayyUserGroup?: WayyUserGroup, options?: any): AxiosPromise<object> {
            return localVarFp.apiUserGroupPost(wayyUserGroup, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserGroupApi - object-oriented interface
 * @export
 * @class UserGroupApi
 * @extends {BaseAPI}
 */
export class UserGroupApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public apiUserGroupDelete(id?: string, options?: AxiosRequestConfig) {
        return UserGroupApiFp(this.configuration).apiUserGroupDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} act 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public apiUserGroupGet(act: string, options?: AxiosRequestConfig) {
        return UserGroupApiFp(this.configuration).apiUserGroupGet(act, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WayyUserGroup} [wayyUserGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public apiUserGroupPost(wayyUserGroup?: WayyUserGroup, options?: AxiosRequestConfig) {
        return UserGroupApiFp(this.configuration).apiUserGroupPost(wayyUserGroup, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserLogApi - axios parameter creator
 * @export
 */
export const UserLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} act 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLogApiUserLogActGet: async (act: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'act' is not null or undefined
            assertParamExists('apiUserLogApiUserLogActGet', 'act', act)
            const localVarPath = `/api/UserLog/api/UserLog/{act}`
                .replace(`{${"act"}}`, encodeURIComponent(String(act)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLogDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/UserLog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLogPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/UserLog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserLogApi - functional programming interface
 * @export
 */
export const UserLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserLogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} act 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserLogApiUserLogActGet(act: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WayyUserLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserLogApiUserLogActGet(act, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserLogDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserLogDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserLogPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserLogPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserLogApi - factory interface
 * @export
 */
export const UserLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserLogApiFp(configuration)
    return {
        /**
         * 
         * @param {string} act 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLogApiUserLogActGet(act: string, options?: any): AxiosPromise<Array<WayyUserLog>> {
            return localVarFp.apiUserLogApiUserLogActGet(act, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLogDelete(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiUserLogDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLogPost(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiUserLogPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserLogApi - object-oriented interface
 * @export
 * @class UserLogApi
 * @extends {BaseAPI}
 */
export class UserLogApi extends BaseAPI {
    /**
     * 
     * @param {string} act 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLogApi
     */
    public apiUserLogApiUserLogActGet(act: string, options?: AxiosRequestConfig) {
        return UserLogApiFp(this.configuration).apiUserLogApiUserLogActGet(act, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLogApi
     */
    public apiUserLogDelete(id?: string, options?: AxiosRequestConfig) {
        return UserLogApiFp(this.configuration).apiUserLogDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLogApi
     */
    public apiUserLogPost(options?: AxiosRequestConfig) {
        return UserLogApiFp(this.configuration).apiUserLogPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ValueSetLogApi - axios parameter creator
 * @export
 */
export const ValueSetLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOdataValueSetLog: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/odata/ValueSetLog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOdataValueSetLogCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/odata/ValueSetLog/$count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiValueSetLogGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ValueSetLog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ValueSetLogApi - functional programming interface
 * @export
 */
export const ValueSetLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ValueSetLogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOdataValueSetLog(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ValueSetLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOdataValueSetLog(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOdataValueSetLogCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ValueSetLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOdataValueSetLogCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiValueSetLogGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ValueSetLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiValueSetLogGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ValueSetLogApi - factory interface
 * @export
 */
export const ValueSetLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ValueSetLogApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOdataValueSetLog(options?: any): AxiosPromise<Array<ValueSetLog>> {
            return localVarFp.apiOdataValueSetLog(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOdataValueSetLogCount(options?: any): AxiosPromise<Array<ValueSetLog>> {
            return localVarFp.apiOdataValueSetLogCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiValueSetLogGet(options?: any): AxiosPromise<Array<ValueSetLog>> {
            return localVarFp.apiValueSetLogGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ValueSetLogApi - object-oriented interface
 * @export
 * @class ValueSetLogApi
 * @extends {BaseAPI}
 */
export class ValueSetLogApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValueSetLogApi
     */
    public apiOdataValueSetLog(options?: AxiosRequestConfig) {
        return ValueSetLogApiFp(this.configuration).apiOdataValueSetLog(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValueSetLogApi
     */
    public apiOdataValueSetLogCount(options?: AxiosRequestConfig) {
        return ValueSetLogApiFp(this.configuration).apiOdataValueSetLogCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValueSetLogApi
     */
    public apiValueSetLogGet(options?: AxiosRequestConfig) {
        return ValueSetLogApiFp(this.configuration).apiValueSetLogGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ValuesApi - axios parameter creator
 * @export
 */
export const ValuesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiValuesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ValuesApi - functional programming interface
 * @export
 */
export const ValuesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ValuesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiValuesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiValuesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ValuesApi - factory interface
 * @export
 */
export const ValuesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ValuesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiValuesGet(options?: any): AxiosPromise<Array<any>> {
            return localVarFp.apiValuesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ValuesApi - object-oriented interface
 * @export
 * @class ValuesApi
 * @extends {BaseAPI}
 */
export class ValuesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValuesApi
     */
    public apiValuesGet(options?: AxiosRequestConfig) {
        return ValuesApiFp(this.configuration).apiValuesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VersionApi - axios parameter creator
 * @export
 */
export const VersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVersionGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VersionApi - functional programming interface
 * @export
 */
export const VersionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VersionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVersionGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVersionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VersionApi - factory interface
 * @export
 */
export const VersionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VersionApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVersionGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiVersionGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VersionApi - object-oriented interface
 * @export
 * @class VersionApi
 * @extends {BaseAPI}
 */
export class VersionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApi
     */
    public apiVersionGet(options?: AxiosRequestConfig) {
        return VersionApiFp(this.configuration).apiVersionGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WayyMessagesApi - axios parameter creator
 * @export
 */
export const WayyMessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WayyMessageCriteria} [wayyMessageCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWayyMessagesPost: async (wayyMessageCriteria?: WayyMessageCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WayyMessages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wayyMessageCriteria, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WayyMessagesApi - functional programming interface
 * @export
 */
export const WayyMessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WayyMessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {WayyMessageCriteria} [wayyMessageCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWayyMessagesPost(wayyMessageCriteria?: WayyMessageCriteria, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WayyMessageSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWayyMessagesPost(wayyMessageCriteria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WayyMessagesApi - factory interface
 * @export
 */
export const WayyMessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WayyMessagesApiFp(configuration)
    return {
        /**
         * 
         * @param {WayyMessageCriteria} [wayyMessageCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWayyMessagesPost(wayyMessageCriteria?: WayyMessageCriteria, options?: any): AxiosPromise<WayyMessageSearchResult> {
            return localVarFp.apiWayyMessagesPost(wayyMessageCriteria, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WayyMessagesApi - object-oriented interface
 * @export
 * @class WayyMessagesApi
 * @extends {BaseAPI}
 */
export class WayyMessagesApi extends BaseAPI {
    /**
     * 
     * @param {WayyMessageCriteria} [wayyMessageCriteria] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WayyMessagesApi
     */
    public apiWayyMessagesPost(wayyMessageCriteria?: WayyMessageCriteria, options?: AxiosRequestConfig) {
        return WayyMessagesApiFp(this.configuration).apiWayyMessagesPost(wayyMessageCriteria, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebChartsApi - axios parameter creator
 * @export
 */
export const WebChartsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} chartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebChartsChartIdGet: async (chartId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chartId' is not null or undefined
            assertParamExists('apiWebChartsChartIdGet', 'chartId', chartId)
            const localVarPath = `/api/WebCharts/{chartId}`
                .replace(`{${"chartId"}}`, encodeURIComponent(String(chartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} chartId 
         * @param {WayyChartSet} [wayyChartSet] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebChartsChartIdNPost: async (chartId: string, wayyChartSet?: WayyChartSet, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chartId' is not null or undefined
            assertParamExists('apiWebChartsChartIdNPost', 'chartId', chartId)
            const localVarPath = `/api/WebCharts/{chartId}/n`
                .replace(`{${"chartId"}}`, encodeURIComponent(String(chartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wayyChartSet, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} chartId 
         * @param {WayyChartSet} [wayyChartSet] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebChartsChartIdPost: async (chartId: string, wayyChartSet?: WayyChartSet, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chartId' is not null or undefined
            assertParamExists('apiWebChartsChartIdPost', 'chartId', chartId)
            const localVarPath = `/api/WebCharts/{chartId}`
                .replace(`{${"chartId"}}`, encodeURIComponent(String(chartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wayyChartSet, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebChartsApi - functional programming interface
 * @export
 */
export const WebChartsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebChartsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} chartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWebChartsChartIdGet(chartId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWebChartsChartIdGet(chartId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} chartId 
         * @param {WayyChartSet} [wayyChartSet] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWebChartsChartIdNPost(chartId: string, wayyChartSet?: WayyChartSet, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWebChartsChartIdNPost(chartId, wayyChartSet, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} chartId 
         * @param {WayyChartSet} [wayyChartSet] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWebChartsChartIdPost(chartId: string, wayyChartSet?: WayyChartSet, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWebChartsChartIdPost(chartId, wayyChartSet, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebChartsApi - factory interface
 * @export
 */
export const WebChartsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebChartsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} chartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebChartsChartIdGet(chartId: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiWebChartsChartIdGet(chartId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} chartId 
         * @param {WayyChartSet} [wayyChartSet] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebChartsChartIdNPost(chartId: string, wayyChartSet?: WayyChartSet, options?: any): AxiosPromise<string> {
            return localVarFp.apiWebChartsChartIdNPost(chartId, wayyChartSet, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} chartId 
         * @param {WayyChartSet} [wayyChartSet] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebChartsChartIdPost(chartId: string, wayyChartSet?: WayyChartSet, options?: any): AxiosPromise<ExternalUrlResponse> {
            return localVarFp.apiWebChartsChartIdPost(chartId, wayyChartSet, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebChartsApi - object-oriented interface
 * @export
 * @class WebChartsApi
 * @extends {BaseAPI}
 */
export class WebChartsApi extends BaseAPI {
    /**
     * 
     * @param {string} chartId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebChartsApi
     */
    public apiWebChartsChartIdGet(chartId: string, options?: AxiosRequestConfig) {
        return WebChartsApiFp(this.configuration).apiWebChartsChartIdGet(chartId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} chartId 
     * @param {WayyChartSet} [wayyChartSet] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebChartsApi
     */
    public apiWebChartsChartIdNPost(chartId: string, wayyChartSet?: WayyChartSet, options?: AxiosRequestConfig) {
        return WebChartsApiFp(this.configuration).apiWebChartsChartIdNPost(chartId, wayyChartSet, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} chartId 
     * @param {WayyChartSet} [wayyChartSet] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebChartsApi
     */
    public apiWebChartsChartIdPost(chartId: string, wayyChartSet?: WayyChartSet, options?: AxiosRequestConfig) {
        return WebChartsApiFp(this.configuration).apiWebChartsChartIdPost(chartId, wayyChartSet, options).then((request) => request(this.axios, this.basePath));
    }
}


