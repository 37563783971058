import React, { useEffect, useState, useCallback, useRef } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Selection
} from 'devextreme-react/data-grid';
import { GetCurrentMyWayyApi } from '../../api/Api'
import { Central } from '../../api/openapi';
import GenericToolbar from '../../components/GenericToolbar'
import notify from 'devextreme/ui/notify';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import FileUploader from 'devextreme-react/file-uploader';
import axios from 'axios';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import events from "./events.json"

const Devices = () => {
    const [data, setData] = useState<Central[]>()
    const [selectedItem, setSelectedItem] = useState<any>(null)
    const [popupVisible, setPopupVisible] = useState(false)
    const [file, setFile] = useState<any>(null)
    const [uploading, setUploading] = useState(false)
    const popupRef = useRef<FileUploader>()

    const refresh = useCallback(() => {
        GetCurrentMyWayyApi().CentralApi.apiCentralGet()
            .then(x => {
                setData(x.data)
            })
    }, [])

    const startServer = useCallback((item: any) => {
        const id = item.id
        // MyWayyApi.ProjectsApi.apiProjectsStartPut(id)
        //   .then(x => {
        //     notify("Ok", "success")
        //     refresh()
        //   }).catch(x => {
        //     notify("Błąd", "error")
        //   })
    }, [])

    const stopServer = useCallback((item: any) => {
        const id = item.id
        // MyWayyApi.ProjectsApi.apiProjectsStopPut(id)
        //   .then(x => {
        //     notify("Ok", "success")
        //     refresh()
        //   }).catch(x => {
        //     notify("Błąd", "error")
        //   })
    }, [])

    const buttons = [
        {
            text: "Odśwież", onClick: () => {
                refresh()
            }
        },
        // { text: "Start", onClick: () => startServer(selectedItem), disabled: selectedItem === null },
        // { text: "Stop", onClick: () => stopServer(selectedItem), disabled: selectedItem === null },
        {
            text: "Zdarzenia", onClick: () => {
                setPopupVisible(true)
            }, disabled: selectedItem === null || !selectedItem.id.includes("etango")
        },
    ]

    useEffect(() => {
        refresh()
    }, [])

    const closePopup = () => {
        setPopupVisible(false)
        setFile(null)
    }


    const closeButtonOptions = {
        icon: 'close',
        text: 'Zamknij',
        onClick: () => closePopup(),
    };

    return (
        <React.Fragment>
            <Popup
                visible={popupVisible}
                onHiding={() => closePopup()}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={false}
                showTitle={true}
                title="Zdarzenia"
                container=".dx-viewport"
                width="80%"
                height="80%"
                disabled={uploading}
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeButtonOptions}
                />
                <DataGrid
                    className={'dx-card wide-card'}
                    height={'100%'}
                    dataSource={events}
                    showBorders={false}
                    focusedRowEnabled={true}
                    defaultFocusedRowIndex={0}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                >

                    <FilterRow visible={true} />
                    <Selection mode="single"
                        showCheckBoxesMode='none'
                    />

                    <Column dataField={'DateTime'} />
                    <Column dataField={'EventCode'} />
                    <Column dataField={'Name'} />
                    {/* <Column dataField={'status'} /> */}

                </DataGrid>
            </Popup>

            <h2 className={'content-block'}>Urządzenia</h2>

            <GenericToolbar header="Odśwież" buttons={buttons} customHeaderRenderer={undefined} toolbarId={undefined} className={undefined}></GenericToolbar>
            <DataGrid
                className={'dx-card wide-card'}
                dataSource={data}
                showBorders={false}
                focusedRowEnabled={true}
                defaultFocusedRowIndex={0}
                columnAutoWidth={true}
                columnHidingEnabled={true}
                keyExpr={"id"}
                onSelectionChanged={x => {
                    if (x.selectedRowsData.length === 1) {
                        setSelectedItem(x.selectedRowsData[0])
                    } else {
                        setSelectedItem(null)
                    }
                }}
            >
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} showInfo={false} />
                <FilterRow visible={true} />
                <Selection mode="single"
                    showCheckBoxesMode='none'
                />

                <Column dataField={'id'} />
                <Column dataField={'name'} />
                {/* <Column dataField={'status'} /> */}

            </DataGrid>
        </React.Fragment>
    )
}

export default Devices