import { DataResponse, SeriesConfig } from './EnergyConsumptionChart';


export interface IDataSource {
  getData(pvId: string, range: string, day: number, month: number, year: number): Promise<DataResponse>;
  getSeriesConfigs(): SeriesConfig[];
}

export enum PVDataRange{
  Day = "day",
  Week = "week",
  Month = "month",
  Year = "year",
  All = "all"
}