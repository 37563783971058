import React, { useCallback, useEffect, useState } from 'react';
import { Chart, Series, ArgumentAxis, ValueAxis, Legend, Export, CommonAxisSettings, Label, Tooltip, Strip } from 'devextreme-react/chart';
import Button from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import moment, { Moment } from 'moment';
import DateBox from 'devextreme-react/date-box';
import { useLocation } from '../../contexts/localization';
import { IDataSource, PVDataRange } from './IDataSource';
import notify from 'devextreme/ui/notify';
import SunCalc from 'suncalc';
import './styles.css'

export interface SeriesDataItem {
  argument: string;
  [key: string]: any;
}

export interface SeriesConfig {
  name: string;
  valueField: string;
  color?: string;
  visible?: boolean;
}

export interface DataResponse {
  total: number;
  unit: string;
  series: {
    argument: string;
    pvProduction: number | null;
  }[]
}

interface EnergyConsumptionChartProps {
  dataSource: IDataSource;
}

function getStartOfWeek(date: moment.Moment): moment.Moment {
  const dayOfWeek = date.day();
  const diff = (dayOfWeek === 0 ? 6 : dayOfWeek - 1);
  return date.clone().subtract(diff, 'days');
}

const generateChartTitle = (timeRange: string, currentDate: moment.Moment) => {
  let title = 'Analiza Energii - ';
  switch (timeRange) {
    case 'day':
      title += currentDate.format('DD.MM.YYYY');
      break;
    case 'week':
      const startOfWeek = getStartOfWeek(currentDate);
      const startOfWeekStr = getStartOfWeek(currentDate).format('DD.MM.YYYY');
      const endOfWeekStr = startOfWeek.add(6, 'days').format('DD.MM.YYYY');
      title += `${startOfWeekStr} - ${endOfWeekStr}`;
      break;
    case 'month':
      title += currentDate.format('MM.YYYY');
      break;
    case 'year':
      title += currentDate.format('YYYY');
      break;
    case 'all':
      title += 'Całość';
      break;
    default:
      title += currentDate.format('MM.YYYY');
  }
  return title;
};

const calculateSunTimes = (date: any, latitude: number, longitude: number) => {
  const times = SunCalc.getTimes(date, latitude, longitude);
  return {
    sunrise: times.sunrise,
    sunset: times.sunset,
  };
};

const formatTime = (date: Date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();

  minutes = Math.round(minutes / 5) * 5;

  if (minutes === 60) {
    minutes = 0;
    hours = (hours + 1) % 24;
  }

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};


const EnergyConsumptionChart: React.FC<EnergyConsumptionChartProps> = ({ dataSource }) => {
  const [data, setData] = useState<DataResponse>({} as DataResponse);
  const [currentDate, setCurrentDate] = useState(moment());
  const [seriesConfigs, setSeriesConfigs] = useState<SeriesConfig[]>([]);
  const [timeRange, setTimeRange] = useState<PVDataRange>(PVDataRange.Day);
  const latitude = 53.1235;
  const longitude = 18.0084;
  const [sunTimes, setSunTimes] = useState<{ sunrise: Date, sunset: Date } | null>(null);

  const { location } = useLocation();

  const fetchData = useCallback(() => {
    const day = currentDate.date();
    const month = currentDate.month() + 1;
    const year = currentDate.year();

    dataSource.getData(location ?? "", timeRange, day, month, year)
      .then(setData)
      .catch(() => notify("Wystąpił błąd podczas pobierania danych", "error"));
  }, [currentDate, dataSource, location, timeRange]);

  useEffect(() => {
    setSeriesConfigs(dataSource.getSeriesConfigs().map(config => ({
      ...config,
      visible: true,
    })));
  }, [dataSource, location]);

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 60000);
    return () => clearInterval(intervalId);
  }, [fetchData]);

  useEffect(() => {
    const { sunrise, sunset } = calculateSunTimes(currentDate, latitude, longitude);
    setSunTimes({ sunrise, sunset });
  }, [currentDate, latitude, longitude]);

  const handlePrev = () => {
    switch (timeRange) {
      case PVDataRange.Day:
        setCurrentDate(currentDate.clone().subtract(1, 'days'));
        break;
      case PVDataRange.Week:
        setCurrentDate(currentDate.clone().subtract(1, 'weeks'));
        break;
      case PVDataRange.Month:
        setCurrentDate(currentDate.clone().subtract(1, 'months'));
        break;
      case PVDataRange.Year:
        setCurrentDate(currentDate.clone().subtract(1, 'years'));
        break;
      default:
        setCurrentDate(currentDate.clone().subtract(1, 'months'));
    }
  };

  const handleNext = () => {
    switch (timeRange) {
      case PVDataRange.Day:
        setCurrentDate(currentDate.clone().add(1, 'days'));
        break;
      case PVDataRange.Week:
        setCurrentDate(currentDate.clone().add(1, 'weeks'));
        break;
      case PVDataRange.Month:
        setCurrentDate(currentDate.clone().add(1, 'months'));
        break;
      case PVDataRange.Year:
        setCurrentDate(currentDate.clone().add(1, 'years'));
        break;
      default:
        setCurrentDate(currentDate.clone().add(1, 'months'));
    }
  };

  const handleLegendClick = (e: any) => {
    const seriesName = e.target.name;
    setSeriesConfigs(currentConfigs =>
      currentConfigs.map(config => ({
        ...config,
        visible: config.name === seriesName ? !config.visible : config.visible,
      }))
    );
  };

  const handleTimeRangeChange = (e: any) => {
    setTimeRange(e.value);
  };

  const handleDateChange = (e: any) => {
    setCurrentDate(moment(e.value));
  };

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        <SelectBox
          items={[
            { id: PVDataRange.Day, name: 'Dzień' },
            { id: PVDataRange.Week, name: 'Tydzień' },
            { id: PVDataRange.Month, name: 'Miesiąc' },
            { id: PVDataRange.Year, name: 'Rok' },
            // { id: 'all', name: 'Całość' }
          ]}
          displayExpr="name"
          valueExpr="id"
          value={timeRange}
          onValueChanged={handleTimeRangeChange}
          width={200}
        />

        <DateBox
          type="date"
          value={currentDate.toDate()}
          onValueChanged={handleDateChange}
          displayFormat="dd/MM/yyyy"
          width={200}
          max={moment().toDate()}
        />
      </div>

      <div className='mw-produced'>{`Produkcja ${data?.total === undefined ? "-" : data.total?.toLocaleString('fr-FR', {
        useGrouping: true,
        maximumFractionDigits: 3,
        minimumFractionDigits: 3
      })} kWh`}</div>
      <Chart
        dataSource={data.series}
        title={generateChartTitle(timeRange, currentDate)}
        // onLegendClick={handleLegendClick}
        animation={false}
      >
        {/* <ArgumentAxis>
          {timeRange === PVDataRange.Day && sunTimes !== null && <Strip startValue={formatTime(sunTimes.sunrise)} endValue={formatTime(sunTimes.sunset)} color="rgba(255, 255, 198, 0.25)">

          </Strip>
          }
        </ArgumentAxis> */}
        <ValueAxis>
          <Label customizeText={(arg: any) => `${arg.value} ${data.unit}`} />
        </ValueAxis>
        {seriesConfigs.map((config, index) => (
          <Series
            key={index}
            valueField={config.valueField}
            argumentField="argument"
            name={config.name}
            type={timeRange === "day" ? "line" : "bar"}
            // type={"bar"}
            color={config.color}
            visible={config.visible}
          />
        ))}

        <Legend verticalAlignment="bottom" horizontalAlignment="center" />
        <Export enabled={true} />
        <Tooltip enabled={true}
          customizeTooltip={(x: any) => customizeTooltip(x, data.unit)}
        />

      </Chart>

      <div>
        <Button
          text="Wstecz"
          onClick={handlePrev}
        />
        <Button
          text="Dalej"
          onClick={handleNext}
        />
      </div>
    </div>
  );
};

const customizeTooltip = (arg: any, unit: string) => {
  return {
    text: `${arg.argumentText}\n${arg.value.toFixed(2)} ${unit}`,
  };
};


export default EnergyConsumptionChart;