import React, { useEffect, useState } from 'react';
import EnergyConsumptionChart from './EnergyConsumptionChart';
import InstallationInfoTable from './InstallationInfoTable'; // Upewnij się, że zaimportowałeś komponent
import { MyWayyDataSource } from './dataSource';
import { useLocation } from '../../contexts/localization';
import ResponsiveBox, {
    Row, Col, Item, Location,
} from 'devextreme-react/responsive-box';

export const EnergyPage: React.FC = () => {
    const dataSource = new MyWayyDataSource();

    const { location } = useLocation();
    const [installationData, setInstallationData] = useState<{ title: string, value: string | JSX.Element }[]>([]);

    useEffect(() => {
        if (location === "abramczyk-kapitanska") {
            setInstallationData([
                { title: 'Nazwa instalacji', value: 'Kapitańska PV' },
                { title: 'Właściciel', value: 'Abramczyk Sp. z o.o.\nul. Inflancka 7\n85-776 Bydgoszcz' },
                { title: 'Lokalizacja instalacji', value: 'Bydgoszcz, Kapitańska 1, działki nr: 101/5, 41/1, 41/2, 47/1, 47/2, 49/1, 49/2, 53/1, 53/2, 56/1, 56/2, obręb 254' },
                { title: 'Całkowita moc instalacji PV', value: '609,16 kW' },
                // Możesz również przekazać elementy JSX jako wartości
                { title: 'Specyfikacja techniczna instalacji', value: (<div>moduły RISEN typ RSM40-8-410M 410 Wp/szt - 1364 szt<br />falownik SUNGROW SG110CX - 5szt<br />moduły LONGI typ LR6-60HPH-320M 320Wp/szt - 156 szt<br />falownik SolarEdge SE25K-RW000BNN4 - 2szt</div>) },
                { title: 'Numer identyfikacyjny małej instalacji w rejestrze wytwórców energii w małej instalacji', value: 'DKN: 71595, nr ident. inst.: 2' },
            ]);
        } else if (location === "abramczyk-inflancka") {
            setInstallationData([
                { title: 'Nazwa instalacji', value: 'Inflancka PV' },
                { title: 'Właściciel', value: 'Abramczyk Sp. z o.o.\nul. Inflancka 7\n85-776 Bydgoszcz' },
                { title: 'Lokalizacja instalacji', value: 'Bydgoszcz, ul. Inflancka 10, działki nr: 105/5, 105/2, obręb 254' },
                { title: 'Całkowita moc instalacji PV', value: '569,8 kW' },
                { title: 'Specyfikacja techniczna instalacji', value: 'moduły RISEN typ RSM40-8-410M 410 Wp/szt - 1268szt\nfalownik SUNGROW SG33CX-P2-V112 - 1szt\nfalownik SUNGROW SG110CX - 4szt\nmoduły LONGI typ LR6-60HPH-320M 320Wp/szt - 156szt\nfalownik SolarEdge SE25K-RW000BNN4 - 2szt' },
                { title: 'Numer identyfikacyjny małej instalacji w rejestrze wytwórców energii w małej instalacji', value: 'DKN: 71595, nr ident. inst.: 1' },
            ]);
        }

    }, [location]);

    const screen = (width: number) => ((width < 700) ? 'sm' : 'lg');

    return (<ResponsiveBox
        singleColumnScreen="sm"
        screenByWidth={screen}
    >
        <Col ratio={2}></Col>
        <Col ratio={1}></Col>
        <Row ratio={1}></Row>
        <Row ratio={1}></Row>

        <Item>
            <Location
                row={0}
                col={0}
                screen="lg"
            ></Location>
            <Location
                row={1}
                col={0}
                screen="sm"
            ></Location>

            <EnergyConsumptionChart dataSource={dataSource} />

        </Item>

        <Item>
            <Location
                row={0}
                col={1}
                screen="lg"
            ></Location>
            <Location
                row={1}
                col={0}
                screen="sm"
            ></Location>

            <InstallationInfoTable data={installationData} />
        </Item>

    </ResponsiveBox>
    );
}
