import { HomePage, DevicesPage } from './pages';
import { withNavigationWatcher } from './contexts/navigation';
import { EnergyPage } from './pages/energy/Energy';
import { ReportsPage } from './pages/reports/Reports';

const routes = [
    {
        path: '/devices',
        element: DevicesPage
    },
    {
        path: '/energy',
        element: EnergyPage
    },
    {
        path: '/reports',
        element: ReportsPage
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
