import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import React, { useEffect, useState } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import './dx-styles.scss';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
// import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import { useKeycloak } from '@react-keycloak/web'
import { SetGetToken } from './api/Api';



const App = () => {
  const { keycloak, initialized } = useKeycloak()
  // const { user, loading } = useAuth();

  useEffect(() => {
    SetGetToken(() => keycloak.token ?? '')
  }, [keycloak]);

  // if (loading) {
  //   return <LoadPanel visible={true} />;
  // }

  if (keycloak.authenticated === true) {
    return <Content />;
  }

  return <LoadPanel visible={true} />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      {/* <AuthProvider> */}
      <NavigationProvider>
        <div className={`app ${screenSizeClass}`}>
          <App />
        </div>
      </NavigationProvider>
      {/* </AuthProvider> */}
    </Router>
  );
}
