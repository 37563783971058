import Keycloak from 'keycloak-js'

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak({
    url: 'https://app.wayy.pl/auth',
    realm: 'master',
    clientId: 'myapp',

    
  })

export default keycloak